import React from "react";
import { Fade } from "react-awesome-reveal";
import { MoveRight } from "lucide-react";
import Typed from 'react-typed';

import Image from "../components/image";
import useDocumentTitle from "../components/setDocumentTitle";
import useDocumentColors from "./../components/setDocumentColors";

import HeroImage from "./../assets/images/contact-hero.jpg";
import HeroImageWebp from "./../assets/images/contact-hero.webp";

const Contact = () => {

  useDocumentTitle("Contact");
  useDocumentColors("var(--color-black)", "var(--color-grey)");

  return (
    <>

      <div className="page-title">
        <div className="row">
          <div className="column column-full">
            
            <Fade direction="down" delay={1000} triggerOnce>
              <div className="page-title__content spaced">
                <span>04</span>
                <span>Contact</span>
              </div>
            </Fade>

          </div>
        </div>
      </div>

      <section className="section section--hero">

        <div className="row">
          <div className="column column-lg-4 column-xl-3">
              <h1>

                <Typed
                  strings={[ "Interested in working with us?" ]}
                  typeSpeed={40}
                  startDelay={500}
                />

              </h1>
          </div>
        </div>
        <div className="row">
          <div className="column column-lg-2">

            <Fade direction="up" delay={1000} triggerOnce>
              <p className="bigger">
                Whether you are making a general enquiry or looking for a prospective partner, please do not hesitate in reaching out to us. Our specialists will handle your enquiries individually and guide you through the process to come up with a unique solution for you to achieve your goals.
              </p>
            </Fade>

          </div>
        </div>

      </section>

      <section className="section section--hero-image">
        <Image jpeg={HeroImage} webp={HeroImageWebp} className="figure--contact-hero" title="Website development layout sketch drawing"/>
      </section>

      <section className="section section--contact-content">

        <div className="row">
          <div className="column column-lg-2">

            <Fade direction="up" triggerOnce>
              <p className="lead">
                We look forward to hearing from you.
              </p>
            </Fade>

          </div>
        </div>

        <div className="row">

          <Fade className="column column-lg-2" cascade triggerOnce>
            <>
              <h2>
                Call us
              </h2>
              <p>
                <a href="tel:+36205000365" className="button button--outline button--icon" target="_blank" rel="noopener noreferrer">
                  <MoveRight className="button__icon" role="img" aria-hidden="true" strokeWidth={1} />
                  <span className="button__text">+36 20 5000 365</span>
                </a>
              </p>
            </>
            <>
              <h2>
                Write us
              </h2>
              <p>
                <a href="mailto:mezei@silberstein.hu" className="button button--outline button--icon" target="_blank" rel="noopener noreferrer">
                  <MoveRight className="button__icon" role="img" aria-hidden="true" strokeWidth={1} />
                  <span className="button__text">mezei@silberstein.hu</span>
                </a>
              </p>
            </>
          </Fade>
        </div>

      </section>


    </>
  );
}

export default Contact;
